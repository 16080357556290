import axios from 'axios';
import uuid4 from 'uuid4';
import Storage from '../utils/Storage';
import Store from './../store/Store';
import VideoStatuses from '../utils/VideoStatuses';
import { prepareSubtitlesToSave } from '../store/Helpers';

class Api {
  constructor() {
    this.baseUrl = 'https://multimedia.instytut-teatralny.pl/api/v1';
  }

  setAuthHeaders() {}

  /**
   * login
   * @param login
   * @param password
   * @returns {Promise<unknown>}
   */
  login({ login, password }) {
    const data = {
      username: login,
      password,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + '/token-auth/', data)
        .then(response => {
          // eslint-disable-next-line no-console
          console.log(
            response && response.data && response.data.token,
            response.data,
            response.data.token,
          );
          if (response && response.data && response.data.token) {
            // eslint-disable-next-line no-console
            console.log('resolve');
            resolve(response.data);
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   * get subtitles
   * @param url
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSubtitles(url) {
    return axios.get(url);
  }

  /**
   * get movie list
   * @param filter
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getMovieList(filter) {
    const token = Storage.get('token');

    // eslint-disable-next-line no-console
    console.log('getMovieList', filter);
    const isLogged = await Store.getIsLogged();
    // eslint-disable-next-line no-console
    console.log('isLogged', isLogged);

    const query = {};
    if (!isLogged) {
      query.status = VideoStatuses.READY;
    }
    if (filter && filter.category) {
      query.category = filter.category;
    }
    if (isLogged && filter && filter.status) {
      query.status = filter.status;
    }
    if (isLogged && filter && filter.active !== '') {
      query.active = filter.active;
    }
    if (filter && filter.searchString) {
      query.searchstring = filter.searchString;
    }
    const queryString = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');

    const headers = {};
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    return axios.get(`${this.baseUrl}/multimedia/?${queryString}`, {
      headers: headers,
    });
  }

  /**
   * get movie details
   * @param id
   * @returns {Promise<unknown>}
   */
  getMovieDetails(id) {
    const token = Storage.get('token');
    const headers = {};
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}/multimedia/${id}/`, {
          headers: headers,
        })
        .then(result => {
          const movie = result.data;
          // eslint-disable-next-line no-console
          console.log(movie);
          const url = movie.transcription.replace('http', 'https');

          this.getSubtitles(url)
            .then(result => {
              let { subtitles } = result.data;
              subtitles = subtitles.map(item => ({
                text: item.text,
                start: +item.start,
                end: +item.end,
                id: uuid4(),
              }));
              movie.subtitles = subtitles;
              resolve(movie);
            })
            // eslint-disable-next-line no-console
            .catch(() => {
              //movie.subtitles = null;
              resolve(movie);
            });
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   * save transcription
   * @returns {Promise<unknown>}
   */
  saveTranscription() {
    const token = Storage.get('token');
    const movie = Store.getMovie();
    const data = prepareSubtitlesToSave(movie);
    const url = movie.transcription.replace('http', 'https');

    return new Promise((resolve, reject) => {
      axios
        .put(url, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  }

  /**
   * upload video
   * @param formData
   * @returns {Promise<unknown>}
   */
  uploadVideo(formData) {
    const token = Storage.get('token');

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl}/multimedia/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
          },
        })
        .then(function(response) {
          // eslint-disable-next-line no-console
          console.log('SUCCESS!!', response);
          if (response.status === 201) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch(function(error) {
          // eslint-disable-next-line no-console
          console.log('FAILURE!!', error);
          reject(false);
        });
    });
  }

  /**
   * edit video
   * @param id
   * @param formData
   * @returns {Promise<unknown>}
   */
  editVideo(id, formData) {
    const token = Storage.get('token');

    return new Promise((resolve, reject) => {
      axios
        .put(`${this.baseUrl}/multimedia/${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
          },
        })
        .then(function(response) {
          // eslint-disable-next-line no-console
          console.log('SUCCESS!!', response);
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch(function(error) {
          // eslint-disable-next-line no-console
          console.log('FAILURE!!', error);
          reject(false);
        });
    });
  }

  /**
   * check api token
   * @param token
   * @returns {Promise<unknown>}
   */
  checkApiToken(token) {
    return new Promise(resolve => {
      axios
        .get(`${this.baseUrl}/token_check/${token}/`)
        .then(result => {
          if (result && result.data) {
            resolve(result.data.is_staff);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  /**
   * search movie
   * @param id
   * @param searchString
   * @returns {Promise<unknown>}
   */
  searchMovie(id, searchString) {
    const token = Storage.get('token');
    const config = {};
    if (token) {
      config.headers = {
        Authorization: `Token ${token}`,
      };
    }

    return new Promise(resolve => {
      axios
        .get(`${this.baseUrl}/multimedia/${id}/?searchstring=${searchString}`, config)
        .then(result => {
          if (result && result.data && result.data.search_result) {
            resolve(result.data.search_result);
          } else {
            resolve([]);
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  /**
   * get playlisted multimedia
   * @returns {Promise<unknown>}
   */
  async getPlaylistedMultimedia(filter) {
    const token = Storage.get('token');

    const isLogged = await Store.getIsLogged();

    const query = {};
    if (!isLogged) {
      query.status = VideoStatuses.READY;
    }
    if (filter && filter.category) {
      query.category = filter.category;
    }
    if (isLogged && filter && filter.status) {
      query.status = filter.status;
    }
    if (isLogged && filter && filter.active !== '') {
      query.active = filter.active;
    }
    if (filter && filter.searchString) {
      query.searchstring = filter.searchString;
    }
    const queryString = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');

    const config = {};
    if (token) {
      config.headers = {
        Authorization: `Token ${token}`,
      };
    }

    return new Promise(resolve => {
      axios
        .get(`${this.baseUrl}/playlisted_multimedia/?${queryString}`, config)
        .then(result => {
          if (result && result.data && result.data.results) {
            const results = result.data.results;
            resolve(results);
          } else {
            resolve([]);
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  /**
   * get playlists for select
   * @returns {Promise<unknown>}
   */
  getPlaylistsForSelect() {
    return new Promise(resolve => {
      this.getPlaylistedMultimedia().then(data => {
        const playlists = data.map(item => ({
          name: item.name,
          id: item.id,
          count: item.multimedias.length,
        }));
        resolve(playlists);
      });
    });
  }

  /**
   * Add a new playlist
   * @param name
   * @returns {Promise<unknown>}
   */
  addNewPlaylist(name) {
    const token = Storage.get('token');

    let formData = new FormData();
    formData.append('name', name);

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl}/playlisted_multimedia/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   * update playlist
   * @param id
   * @param name
   * @returns {Promise<unknown>}
   */
  updatePlaylist({ id, name }) {
    const token = Storage.get('token');

    let formData = new FormData();
    formData.append('name', name);

    return new Promise((resolve, reject) => {
      axios
        .put(`${this.baseUrl}/playlisted_multimedia/${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}

export default new Api();
