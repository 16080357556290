import VideoStatuses from "./VideoStatuses";

export const getMovieStatuses = () => {
  return [
    { id: VideoStatuses.WAITING, value: "W przygotowaniu" },
    { id: VideoStatuses.EXTRACTING_AUDIO, value: "Przygotowanie audio" },
    { id: VideoStatuses.SENDING_AUDIO, value: "Wysyłanie audio" },
    {
      id: VideoStatuses.WAITING_FOR_TRANSCRIPTION,
      value: "Oczekiwanie na tłumaczenie"
    },
    {
      id: VideoStatuses.PROCESSING_TRANSCRIPTION,
      value: "Opacowanie tłumaczenia"
    },
    { id: VideoStatuses.READY, value: "Zakończony" }
  ];
};
