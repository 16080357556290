import Store from './Store';
import Storage from '../utils/Storage';
import Api from '../services/Api';

export const changeCurrentSubtitle = (subtitleId, dir) => {
  if (!subtitleId) {
    return false;
  }
  const { subtitles } = Store.getMovie();

  let index = subtitles.findIndex(item => item.id === subtitleId);

  if (dir === 'prev') {
    index = index > 0 ? index - 1 : 0;
  } else {
    index = index < subtitles.length - 1 ? index + 1 : subtitles.length - 1;
  }

  Store.setCurrentSubtitle(subtitles[index]);
  return true;
};

export const updateSubtitle = ({ text, start, end, id }) => {
  const { subtitles } = Store.getMovie();
  let index = subtitles.findIndex(item => item.id === id);

  let subtitle = subtitles[index];
  subtitle.text = text;
  subtitle.start = +start;
  subtitle.end = +end;

  if (index > 0) {
    const prevSubtitle = subtitles[index - 1];
    if (prevSubtitle.end > subtitle.start) {
      prevSubtitle.end = subtitle.start;
    }
  }
  if (index < subtitles.length - 1) {
    const nextSubtitle = subtitles[index + 1];
    if (nextSubtitle.start < subtitle.end) {
      nextSubtitle.start = subtitle.end;
    }
  }

  Store.event.$emit('subtitlesUpdated');
};

export const checkIsLogged = async () => {
  const token = Storage.get('token');
  if (!token) {
    return false;
  }
  try {
    const result = await Api.checkApiToken(token);
    return !!result;
  } catch (e) {
    return false;
  }
};

export const prepareSubtitlesToSave = movie => {
  const { subtitles } = movie;
  const subtitlesParsed = subtitles.map(item => {
    return { text: item.text, start: item.start, end: item.end };
  });
  return { subtitles: subtitlesParsed };
};
