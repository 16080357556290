<template>
  <perfect-scrollbar
    class="movie-search-results-container"
    :style="{ height: height ? height + 'px' : '100%' }"
  >
    <div v-if="results.length > 0">
      <div
        class="search-result-item"
        v-for="(result, index) in results"
        :key="index"
        :data-start="result.start"
        @click="searchResultClickHandler"
      >
        {{ result.text }}
      </div>
    </div>
    <div v-if="results.length === 0">
      <p>Brak wyników wyszukiwania</p>
      <div>
        <button
          type="button"
          class="btn btn-light"
          @click="searchResultClickHandler"
        >
          Wróć
        </button>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
export default {
  name: "MovieSearchResults",
  props: ["results", "clickHandler"],
  data() {
    return {
      height: null
    };
  },
  created() {
    this.height = window.innerHeight - 100;
  },
  methods: {
    searchResultClickHandler($event) {
      this.clickHandler($event.target.dataset.start);
    }
  }
};
</script>

<style scoped></style>
