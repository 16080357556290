<template>
  <div class="container site-404">
    <h3>Strona nie znaleziona</h3>
    <router-link to="/">
      Wróć na stronę główną
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
