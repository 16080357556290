class Storage {
  static set(key, value) {
    localStorage[key] = value;
  }

  static get(key, defaultValue = undefined) {
    return localStorage[key] || defaultValue;
  }

  static destroy(key) {
    return localStorage.removeItem(key);
  }
}

export default Storage;
