<template>
  <div class="player">
    <div class="mediaplayer">
      <video
        ref="video"
        @timeupdate="getCurrentTime($event.target.currentTime)"
        @canplaythrough="videoLoaded()"
        :poster="movie ? movie.thumbnail : null"
        controls
        preload="none"
      >
        <source v-if="movie" :src="movie.file" type="video/mp4" />
      </video>
      <img
        src="static/img/video-logo.png"
        data-href="https://www.instytut-teatralny.pl/"
        @click="handlerClickWatermark"
      />
    </div>
  </div>
</template>

<script>
import Store from "../store/Store";

export default {
  name: "VideoPlayer",
  props: ["movie"],
  data() {
    return {
      track: undefined,
      currentTime: undefined,
      isVideoLoaded: false,
      isSubtitlesLoaded: false,
      isCueAdded: false
    };
  },
  created() {
    Store.event.$on("subtitleClickEvent", time => {
      this.setCurrentTime(time);
    });

    Store.event.$on("subtitlesUpdated", () => {
      this.track.cues = [];
      this.track.shimActiveCues.length &&
        this.track.shimActiveCues.splice(0, this.track.shimActiveCues.length);
      this.track._shimActiveCues.length &&
        this.track._shimActiveCues.splice(0, this.track._shimActiveCues.length);
      this.track.activeCues.length &&
        this.track.activeCues.splice(0, this.track.activeCues.length);
      // this.track
      for (let i = 0, l = this.movie.subtitles.length; i < l; i += 1) {
        this.track.addCue(
          new VTTCue(
            this.movie.subtitles[i].start,
            this.movie.subtitles[i].end,
            this.movie.subtitles[i].text
          )
        );
      }
    });
  },
  beforeDestroy() {
    Store.event.$off("subtitleClickEvent");
    Store.event.$off("subtitlesUpdated");
  },
  mounted() {
    Store.setVideoElement(this.$refs["video"]);
  },
  methods: {
    init() {
      // eslint-disable-next-line no-undef
      webshims.setOptions("mediaelement", {
        replaceUI: "true"
      });
      // eslint-disable-next-line no-undef
      webshims.setOptions("track", {
        override: "true"
      });
      // eslint-disable-next-line no-undef
      webshims.polyfill("mediaelement track");

      // eslint-disable-next-line no-undef
      $("body").updatePolyfill();
    },
    setCurrentTime(time) {
      if (this.$refs["video"]) {
        this.$refs["video"].currentTime = time;
        if (this.$refs["video"].paused) {
          this.$refs["video"].play();
        }
      }
    },

    getCurrentTime(time) {
      this.$emit("currentTime", time);
    },
    videoLoaded() {
      if (!this.isCueAdded) {
        this.isVideoLoaded = true;
        this.addCue();
      }
    },
    addCue() {
      if (
        //this.track &&
        this.isVideoLoaded &&
        this.isSubtitlesLoaded &&
        !this.isCueAdded
      ) {
        this.isCueAdded = true;
        // eslint-disable-next-line no-console
        console.info("--- addCue ---");

        // eslint-disable-next-line no-undef
        this.track = $(this.$refs["video"]).addTextTrack(
          "subtitles",
          "subtitles",
          "pl"
        );
        this.track.mode = "showing";
        // eslint-disable-next-line no-console
        console.log(this.movie, this.track);

        for (let i = 0, l = this.movie.subtitles.length; i < l; i += 1) {
          this.track.addCue(
            new VTTCue(
              this.movie.subtitles[i].start,
              this.movie.subtitles[i].end,
              this.movie.subtitles[i].text
            )
          );
        }
      }
    },
    handlerClickWatermark(event) {
      window.open(event.target.dataset.href, "_blank");
    }
  },
  watch: {
    movie: {
      deep: true,
      handler: function(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.init();
        }
        if (newValue && newValue.subtitles) {
          this.isSubtitlesLoaded = true;
          this.addCue();
        }
      }
    }
  }
};
</script>

<style scoped></style>
