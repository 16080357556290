<template>
  <div id="app">
    <router-link v-if="isLogged" class="nav-link" to="/logout/">Wyloguj</router-link>
    <div class="container-fluid">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Store from './store/Store';

export default {
  name: 'app',
  data() {
    return {
      isLogged: false,
    };
  },
  mounted() {
    Store.getIsLogged().then(result => (this.isLogged = !!result));

    Store.event.$on('changeLoggedEvent', () => {
      Store.getIsLogged().then(result => (this.isLogged = !!result));
    });
  },
  components: {},
};
</script>
