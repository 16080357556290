class VideoStatuses {
  static WAITING = "W";
  static EXTRACTING_AUDIO = "E";
  static SENDING_AUDIO = "S";
  static WAITING_FOR_TRANSCRIPTION = "T";
  static PROCESSING_TRANSCRIPTION = "P";
  static READY = "R";
}

export default VideoStatuses;
