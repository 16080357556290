<template>
  <div />
</template>

<script>
import Store from "./../store/Store";
import Storage from "../utils/Storage";

export default {
  name: "Logout",
  mounted() {
    Storage.destroy("token");
    Store.setIsLogged(false);
    window.location.href = "/";
  }
};
</script>
