<template>
  <b-container class="playlist-site mt-4">
    <div class="row">
      <div class="col-10">
        <h1>Zarządzanie playlistami</h1>
      </div>
      <div class="col-2">
        <b-button size="sm" variant="dark" @click="handleCreate">
          <span class="glyphicon glyphicon-sort">+ Dodaj playlistę</span>
        </b-button>
      </div>
    </div>
    <div class="row" v-if="editedPlaylist">
      <div class="col-md-12">
        <div v-if="editError" class="alert alert-warning" role="alert">
          Proszę podać nazwę playlisty!
        </div>
        <div class="card bg-light mb-3">
          <div class="card-header" v-if="editedPlaylist.id">Edycja playlisty</div>
          <div class="card-header" v-if="!editedPlaylist.id">Dodawanie playlisty</div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>Nazwa:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="editedPlaylist.name"
                  placeholder=""
                />
              </div>
            </form>
            <div class="row float-right button-wrapper">
              <button type="button" class="btn btn-light" @click="handleEditCancel">
                Anuluj
              </button>
              <button type="button" class="btn btn-primary" @click="handleEditSave">
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-table :items="playlists" :fields="fields">
      <template #cell(actions)="row">
        <b-button size="sm" @click="handleEdit(row.item)" class="mr-1">
          Edycja
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import Store from '../store/Store';
import Api from '../services/Api';

export default {
  name: 'Playlists',
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'count', label: 'Ilość filmów' },
        { key: 'actions', label: 'Akcja' },
      ],
      playlists: [],
      editedPlaylist: null,
      editError: false,
    };
  },
  created() {
    Api.getPlaylistsForSelect().then(playlist => {
      this.playlists = playlist;
    });
  },
  mounted() {
    Store.getIsLogged().then(result => {
      if (!result) {
        this.$router.push('/');
      }
    });
  },
  methods: {
    handleEdit({ id, name }) {
      this.editedPlaylist = { id, name };
    },
    handleCreate() {
      this.editedPlaylist = { id: null, name: '' };
    },
    handleEditCancel() {
      this.editedPlaylist = null;
      this.editError = false;
    },
    handleEditSave() {
      if (!this.editedPlaylist.name.trim().length) {
        this.editError = true;
        return false;
      } else {
        this.editError = false;

        if (!this.editedPlaylist.id) {
          // create
          Api.addNewPlaylist(this.editedPlaylist.name)
            .then(() => {
              Api.getPlaylistsForSelect().then(playlist => {
                this.playlists = playlist;
              });
              this.editedPlaylist = null;
            })
            .catch(() => {
              this.$bvToast.toast(`Wystąpił problem podczas tworzenia nowej playlisty`, {
                title: 'Uwaga',
                autoHideDelay: 5000,
              });
            });
        } else {
          // update
          Api.updatePlaylist(this.editedPlaylist)
            .then(() => {
              Api.getPlaylistsForSelect().then(playlist => {
                this.playlists = playlist;
              });
              this.editedPlaylist = null;
            })
            .catch(() => {
              this.$bvToast.toast(`Wystąpił problem podczas edycji playlisty`, {
                title: 'Uwaga',
                autoHideDelay: 5000,
              });
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.button-wrapper {
  padding: 5px;
}
</style>
