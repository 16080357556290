<template>
  <div class="container-fluid movie-list-component">
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6 text-right add-movie-button-column">
        <b-button size="sm" variant="dark" v-if="isLogged" @click="goToVideoUploader">
          <span class="glyphicon glyphicon-sort">+ Dodaj film</span>
        </b-button>
        <b-button size="sm" variant="dark" v-if="isLogged" @click="goToPlaylistManager">
          <span class="glyphicon glyphicon-sort">Playlisty</span>
        </b-button>
      </div>
    </div>

    <div class="row movie-filters">
      <div class="col-4">
        <div class="form-inline mt-4">
          <div class="form-group mb-2">
            <div class="wrap-out">
              <section class="block-searchbox" id="search">
                <div>
                  <input
                    class="s"
                    autocomplete="off"
                    type="text"
                    placeholder="Szukaj"
                    id="searchInput"
                    v-model="filter.searchString"
                    v-on:keyup.enter="searchMovie"
                  />
                  <button type="submit" @click="searchMovie">→</button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 offset-md-2">
        <div class="form-group" v-if="isLogged">
          <label>Aktywny:</label>
          <select v-model="filter.active" class="form-control" @change="searchMovie">
            <option value="">-- Wybierz --</option>
            <option value="false">Nieaktywny</option>
            <option value="true">Aktywny</option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group" v-if="isLogged">
          <label>Status:</label>
          <select v-model="filter.status" class="form-control" @change="searchMovie">
            <option value="">-- Wybierz --</option>
            <option v-for="status in this.movieStatuses" :value="status.id" :key="status.id"
              >{{ status.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label>Kategoria:</label>
          <select v-model="filter.category" class="form-control" @change="searchMovie">
            <option value="">-- Wybierz --</option>
            <option
              v-for="category in this.movieCategories"
              :value="category.id"
              :key="category.id"
              >{{ category.value }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="movie-list-results">
      <div class="movie-list-playlist" v-for="playlist in playlists" :key="playlist.id">
        <header class="block-postlist_header">
          <div class="wrap-out">
            <h1 class="title">
              <span class="hilite">{{ playlist.name }}</span>
            </h1>
          </div>
        </header>
        <div class="movie-list-movies row">
          <div class="col" v-for="movie in playlist.multimedias" :key="movie.id">
            <div class="postlink">
              <router-link :to="`/movie/${movie.slug}-${movie.id}`" class="in">
                <div class="pix">
                  <img
                    class="b-lazy b-loaded"
                    :src="movie.thumbnail ? movie.thumbnail : videoPlaceholder"
                    :alt="movie.name"
                  />

                  <div class="playb"><span>▻</span></div>
                </div>
                <h2 class="txt" v-html="movie.name"></h2>
                <div class="lead"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../services/Api';
import Store from './../store/Store';
import { getMovieCategories } from '../utils/MovieCategoriesEnum';
import { getMovieStatuses } from '../utils/MovieStatusesEnum';

export default {
  name: 'MovieList',
  data() {
    return {
      filter: {
        category: '',
        status: '',
        active: '',
        searchString: '',
      },
      movies: [],
      playlists: [],
      isLogged: false,
      movieCategories: getMovieCategories(),
      movieStatuses: getMovieStatuses(),
    };
  },
  created() {
    Store.getIsLogged().then(result => (this.isLogged = !!result));

    // this.fetchMovies(this.filter);
    this.fetchPlaylistedMovies(this.filter);
  },
  filters: {
    truncate: function(text, length, clamp = '...') {
      if (text === null) return '';
      return text.slice(0, length) + (length < text.length ? clamp : '');
    },
  },
  methods: {
    fetchPlaylistedMovies(filter) {
      Api.getPlaylistedMultimedia(filter).then(response => {
        this.filter.searchString = '';
        if (response) {
          this.playlists = response.filter(playlist => playlist.multimedias.length);
          // Store.setMovies(this.movies);
        }
      });
    },

    fetchMovies(filter) {
      Api.getMovieList(filter).then(response => {
        this.filter.searchString = '';
        if (response.data && response.data.results) {
          this.movies = response.data.results;
          Store.setMovies(this.movies);
        }
      });
    },
    goToVideoUploader() {
      this.$router.push('/video/upload');
    },
    goToPlaylistManager() {
      this.$router.push('/playlists');
    },
    searchMovie() {
      this.fetchPlaylistedMovies(this.filter);
    },
  },
  computed: {
    videoPlaceholder() {
      return require('@/assets/video-placeholder.png');
    },
  },
};
</script>
