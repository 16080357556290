<template>
  <div class="subtitle-editor row">
    <div class="col-11 col-subtitle-editor">
      <div class="card movie-manager" v-show="isEditMode">
        <div class="current-time" v-show="isEditMode">
          {{ currentTimeFormatted }}
        </div>
        <div class="card-body row">
          <div class="col-7">
            <label for="text">Transkrypcja:</label>
            <b-form-input
              id="textarea"
              v-model="text"
              type="text"
            ></b-form-input>
            <div class="row save-buttons">
              <div class="col-6">
                <b-button size="sm" variant="secondary" @click="saveSubtitle"
                  >Uaktualnij</b-button
                >
              </div>
              <div class="col-6 text-right">
                <b-button size="sm" variant="secondary" @click="saveAll"
                  >Zapisz zmiany</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="row">
              <div class="col">
                <label for="start">Start:</label>

                <div class="input-group">
                  <b-form-input
                    id="start"
                    v-model="start"
                    type="number"
                    step="0.1"
                  ></b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text" @click="copyStartTime"
                      >↤</span
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="end">End:</label>
                <div class="input-group">
                  <b-form-input
                    id="end"
                    v-model="end"
                    type="number"
                    step="0.1"
                  ></b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text" @click="copyEndTime">↤</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row subtitle-editor-buttons">
              <div class="col-6">
                <b-button size="sm" variant="secondary" @click="playFragment">
                  <span class="glyphicon glyphicon-sort">▶</span>
                </b-button>
                <b-button size="sm" variant="secondary" @click="pauseFragment">
                  <span class="glyphicon glyphicon-sort">▮▮</span>
                </b-button>
              </div>
              <div class="col-6 text-right">
                <b-button size="sm" variant="secondary" @click="changePrev">
                  <span class="glyphicon glyphicon-sort">«</span>
                </b-button>
                <b-button size="sm" variant="secondary" @click="changeNext">
                  <span class="glyphicon glyphicon-sort">»</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 col-edit-mode-btn">
      <button
        type="button"
        class="btn btn-is-edit-mode"
        :class="[isEditMode ? 'btn-light' : 'btn-dark']"
        @click="clickHandler"
      >
        ✎
      </button>
    </div>
    <FullScreenLoader :is-loading="isSaving" />
  </div>
</template>

<script>
import Store from "./../store/Store";
import { changeCurrentSubtitle, updateSubtitle } from "../store/Helpers";
import FullScreenLoader from "./FullScreenLoader";
import Api from "../services/Api";

export default {
  name: "SubtitleEditor",
  components: { FullScreenLoader },
  props: ["currentTime"],
  data() {
    return {
      video: null,
      isEditMode: false,
      text: "",
      start: "",
      end: "",
      currentSubtitleId: null,
      isSaving: false
    };
  },
  mounted() {
    this.video = Store.getVideoElement();
    Store.event.$on("subtitleClickEvent", () => {
      this.refreshCurrentSubtitle();
    });
  },
  computed: {
    currentTimeFormatted: function() {
      return this.currentTime ? `${this.currentTime.toFixed(1)} sec.` : "";
    }
  },
  methods: {
    refreshCurrentSubtitle() {
      const subtitle = Store.getCurrentSubtitle();
      if (subtitle) {
        this.text = subtitle.text;
        this.start = subtitle.start;
        this.end = subtitle.end;
        this.currentSubtitleId = subtitle.id;
      }
    },
    clickHandler() {
      this.isEditMode = !this.isEditMode;
      Store.setIsEditMode(this.isEditMode);
    },
    playFragment() {
      if (this.start !== "" && this.end !== "" && this.start < this.end) {
        this.video.currentTime = this.start;
        this.video.play();
      }
    },
    pauseFragment() {
      this.video.pause();
    },
    changePrev() {
      changeCurrentSubtitle(this.currentSubtitleId, "prev") &&
        this.refreshCurrentSubtitle();
    },
    changeNext() {
      changeCurrentSubtitle(this.currentSubtitleId, "next") &&
        this.refreshCurrentSubtitle();
    },
    copyStartTime() {
      if (this.currentTime !== null && this.start !== "") {
        this.start = +this.currentTime.toFixed(1);
      }
    },
    copyEndTime() {
      if (this.currentTime !== null && this.end !== "") {
        this.end = +this.currentTime.toFixed(1);
      }
    },
    saveSubtitle() {
      updateSubtitle({
        text: this.text,
        start: this.start,
        end: this.end,
        id: this.currentSubtitleId
      });
    },
    saveAll() {
      this.isSaving = true;
      Api.saveTranscription().then(() => {
        this.isSaving = false;
        this.$bvToast.toast(`Transkrypcja zapisana`, {
          title: "Sukces",
          autoHideDelay: 5000
        });
      });
    }
  }
};
</script>
