<template>
  <div>
    <div class="row movie-details-header">
      <div class="col-8">
        <h5>
          <span v-if="movie" v-html="movie.name"></span>
          <router-link
            v-if="movie && isLogged"
            :to="`/edit/${movie.slug}-${movie.id}`"
            class="image-link"
          >
            <button type="button" class="btn btn-is-edit-mode image-link">
              <svg
                class="bi bi-pencil"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"
                />
              </svg>
            </button>
          </router-link>
          <b-button
            v-if="movie && !isLogged"
            class="image-link"
            variant="light"
            v-b-modal="'videoDetailsModal'"
          >
            <svg
              class="bi bi-info-circle"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
              />
              <circle cx="8" cy="4.5" r="1" />
            </svg>
          </b-button>
        </h5>
      </div>
      <div class="col-4">
        <div class="form-inline float-right">
          <div class="form-group mb-2">
            <label for="searchInput" class="mr-2">Szukaj</label>
            <input
              type="text"
              class="form-control"
              id="searchInput"
              v-model="searchString"
              v-on:keyup.enter="searchInMovieHandler"
            />
          </div>
          <div class="form-group mx-sm-3">
            <button type="submit" class="btn btn-primary mb-2" @click="searchInMovieHandler">
              <svg
                class="bi bi-search"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                />
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row movie-details">
      <div class="col-md-7">
        <VideoPlayer :movie="movie" @currentTime="getVideoCurrentTime" />
        <SubtitleEditor :currentTime="currentTime" v-if="isLogged" />
      </div>
      <div class="col-md-5">
        <Subtitles
          v-if="!showMovieSearchResults"
          :subtitles="movie ? movie.subtitles : []"
          :currentTime="currentTime"
        />
        <MovieSearchResults
          v-if="showMovieSearchResults"
          :results="searchResults"
          :clickHandler="resultClickHandler"
        />
      </div>
    </div>
    <b-modal id="videoDetailsModal" title="Szczegóły filmu" ok-only ok-title="Zamknij">
      <div>
        <table class="table table-striped" v-if="movie">
          <tr v-if="movie.name">
            <td>Tytuł:</td>
            <td v-html="movie.name"></td>
          </tr>
          <tr v-if="movie.description">
            <td>Opis:</td>
            <td v-html="movie.description"></td>
          </tr>
          <tr>
            <td>Czas trwania:</td>
            <td v-html="movie.multimedia_duration"></td>
          </tr>
          <tr v-if="movie.date">
            <td>Data:</td>
            <td v-html="movie.date"></td>
          </tr>
          <tr v-if="categoryName">
            <td>Kategoria:</td>
            <td v-html="categoryName"></td>
          </tr>
          <tr v-if="movie.project">
            <td>Cykl/projekt:</td>
            <td v-html="movie.project"></td>
          </tr>
          <tr v-if="movie.signature">
            <td>Sygnatura:</td>
            <td v-html="movie.signature"></td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Api from '../services/Api';
import Subtitles from './Subtitles';
import VideoPlayer from './VideoPlayer';
import SubtitleEditor from './SubtitleEditor';
import MovieSearchResults from './MovieSearchResults';
import Store from './../store/Store';
import { getCategoryName } from '../utils/MovieCategoriesEnum';

export default {
  name: 'MovieDetails',
  components: { SubtitleEditor, VideoPlayer, Subtitles, MovieSearchResults },
  data() {
    return {
      movie: null,
      currentTime: null,
      isLogged: false,
      searchString: '',
      searchResults: null,
      showMovieSearchResults: false,
    };
  },
  computed: {
    categoryName() {
      return this.movie ? getCategoryName(this.movie.category) : '';
    },
  },
  created() {
    if (!this.$route.params.slugId) {
      this.$router.push('/not-found');
    }

    Store.getIsLogged().then(result => (this.isLogged = !!result));

    const params = this.$route.params.slugId.split('-');
    const id = parseInt(params.pop());

    Api.getMovieDetails(id)
      .then(movie => {
        this.movie = movie;
        Store.setMovie(movie);
      })
      .catch(() => {
        this.$router.push('/not-found');
      });
  },
  methods: {
    getVideoCurrentTime(time) {
      this.currentTime = time;
    },
    searchInMovieHandler() {
      if (this.searchString.length) {
        Api.searchMovie(this.movie.id, this.searchString).then(results => {
          this.searchString = '';
          this.searchResults = results;
          this.showMovieSearchResults = true;
        });
      }
    },
    resultClickHandler(time) {
      this.showMovieSearchResults = false;
      if (time) {
        Store.event.$emit('subtitleClickEvent', parseFloat(time));
      }
    },
  },
};
</script>

<style scoped></style>
