<template>
  <div class="container site-video-uploader edit-video">
    <div class="row" v-if="video">
      <div class="col-md-8 offset-md-2">
        <div class="card bg-light mb-3">
          <div class="card-header">Kod iframe:</div>
          <div class="card-body">
            <textarea style="width: 100%;height:80%;" v-model="iframeHtml"></textarea>
          </div>
        </div>

        <div v-if="error" class="alert alert-warning" role="alert">
          Proszę uzupełnić brakujące pola!
        </div>
        <div class="card bg-light mb-3">
          <div class="card-header">Edycja filmu</div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>Tytuł:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.name" placeholder="" />
              </div>

              <div class="form-group">
                <label>Opis:</label>
                <wysiwyg rows="3" v-model="video.description" placeholder="" />
              </div>

              <div class="form-group">
                <label>Opis skócony:</label>
                <wysiwyg rows="3" v-model="video.short_description" placeholder="" />
              </div>

              <div class="form-group">
                <label>Data:</label>
                <masked-input
                  v-model="video.date"
                  mask="1111-11-11"
                  placeholder="np. 2020-04-20"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Kategoria:</label>
                <select v-model="video.category" class="form-control">
                  <option
                    v-for="category in this.movieCategories"
                    :value="category.id"
                    :key="category.id"
                    >{{ category.value }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Playlista:</label>
                <select v-model="video.playlist" class="form-control">
                  <option
                    v-for="playlist in this.playlists"
                    :value="playlist.id"
                    :key="playlist.id"
                    >{{ playlist.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Cykl/projekt:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.project" placeholder="" />
              </div>

              <div class="form-group">
                <label>Sygnatura:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.signature" placeholder="" />
              </div>

              <div v-if="video.thumbnail" class="form-group">
                <label>Obecna miniaturka filmu</label>
                <img
                  :src="video.thumbnail"
                  alt="miniaturka filmu"
                  class="edit-video-thumbnail"
                />
              </div>

              <div class="form-group">
                <label>Plik podglądu video (miniaturka):</label>
                <input
                  type="file"
                  class="form-control input-file"
                  ref="thumbnail"
                  @change="handleThumbnailUpload"
                />
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="video.active"
                  id="activeCheckbox"
                />
                <label class="form-check-label" for="activeCheckbox">
                  Aktywny
                </label>
              </div>

              <button type="button" class="btn btn-secondary float-right" @click="handleEdit">
                Zapisz
              </button>
              <button
                type="button"
                class="btn btn-primary float-right btn-cancel"
                @click="handleCancel"
              >
                Anuluj
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <FullScreenLoader :is-loading="isSaving" />
  </div>
</template>

<script>
//import Api from "../services/Api";
import FullScreenLoader from './FullScreenLoader';
import Store from '../store/Store';
import MaskedInput from 'vue-masked-input';
import { getMovieCategories } from '../utils/MovieCategoriesEnum';
import Api from '../services/Api';

export default {
  name: 'VideoEdit',
  components: { FullScreenLoader, MaskedInput },
  data() {
    return {
      movieCategories: getMovieCategories(),
      isSaving: false,
      error: false,
      video: null,
      thumbnail: null,
      playlists: [],
    };
  },
  computed: {
    iframeHtml() {
      return `<iframe width="1120" height="630" src="${location.origin}/iframe.html?video=${this.video.slug}-${this.video.id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    },
  },
  created() {
    Store.getIsLogged().then(result => {
      if (!result) {
        this.$router.push('/');
      }
    });
    if (!this.$route.params.editSlugId) {
      this.$router.push('/not-found');
    }
    const params = this.$route.params.editSlugId.split('-');
    const id = parseInt(params.pop());

    Api.getPlaylistsForSelect().then(playlist => {
      this.playlists = playlist;
    });

    Api.getMovieDetails(id)
      .then(movie => {
        this.video = movie;
        Store.setMovie(movie);
      })
      .catch(() => {
        this.$router.push('/not-found');
      });
  },
  methods: {
    handleThumbnailUpload() {
      this.thumbnail = this.$refs.thumbnail.files[0];
    },
    handleCancel() {
      this.$router.push(`/movie/${this.$route.params.editSlugId}`);
    },
    handleEdit() {
      if (!this.video.name || !this.video.name.trim().length) {
        this.error = true;
        return false;
      }

      this.error = false;

      let formData = new FormData();
      formData.append('id', this.video.id);
      formData.append('name', this.video.name);
      formData.append('description', this.video.description);
      formData.append('short_description', this.video.short_description);
      formData.append('date', this.video.date);
      formData.append('category', this.video.category);
      formData.append('project', this.video.project);
      formData.append('active', this.video.active);
      formData.append('signature', this.video.signature);
      formData.append('playlist', this.video.playlist);
      if (this.thumbnail) {
        formData.append('thumbnail', this.thumbnail);
      }

      this.isSaving = true;

      Api.editVideo(this.video.id, formData)
        .then(() => {
          this.$bvToast.toast(`Dane zostały zapisane`, {
            title: 'Sukces',
            autoHideDelay: 5000,
          });
          this.$router.push('/');
        })
        .catch(() => {
          this.isUploading = false;
          this.$bvToast.toast(`Wystąpił problem podczas wgrywania filmu`, {
            title: 'Uwaga',
            autoHideDelay: 5000,
          });
        });
    },
  },
};
</script>
<style scoped>
.btn-cancel {
  margin-right: 10px;
}
</style>
