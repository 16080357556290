<template>
  <div class="container site-video-uploader">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div v-if="video.error" class="alert alert-warning" role="alert">
          Proszę uzupełnić brakujące pola!
        </div>
        <div class="card bg-light mb-3">
          <div class="card-header">Dodawanie nowego filmu</div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>Tytuł:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.name" placeholder="" />
              </div>

              <div class="form-group">
                <label>Opis:</label>
                <wysiwyg rows="3" v-model="video.description" placeholder="" />
              </div>

              <div class="form-group">
                <label>Opis skócony:</label>
                <wysiwyg rows="3" v-model="video.short_description" placeholder="" />
              </div>

              <div class="form-group">
                <label>Data:</label>
                <masked-input
                  v-model="video.date"
                  mask="1111-11-11"
                  placeholder="np. 2020-04-20"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Kategoria:</label>
                <select v-model="video.category" class="form-control">
                  <option
                    v-for="category in this.movieCategories"
                    :value="category.id"
                    :key="category.id"
                    >{{ category.value }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Playlista:</label>
                <select v-model="video.playlist" class="form-control">
                  <option
                    v-for="playlist in this.playlists"
                    :value="playlist.id"
                    :key="playlist.id"
                    >{{ playlist.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Cykl/projekt:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.project" placeholder="" />
              </div>

              <div class="form-group">
                <label>Sygnatura:</label>
                <wysiwyg class="tiny-wyswig" v-model="video.signature" placeholder="" />
              </div>

              <div class="form-group">
                <label>Plik video:</label>
                <input
                  type="file"
                  class="form-control input-file"
                  ref="file"
                  @change="handleFileUpload"
                />
              </div>

              <div class="form-group">
                <label>Plik podglądu video (miniaturka):</label>
                <input
                  type="file"
                  class="form-control input-file"
                  ref="thumbnail"
                  @change="handleThumbnailUpload"
                />
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="video.active"
                  id="activeCheckbox"
                />
                <label class="form-check-label" for="activeCheckbox">
                  Aktywny
                </label>
              </div>

              <button type="button" class="btn btn-secondary float-right" @click="submitFile">
                Zapisz
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <FullScreenLoader :is-loading="isUploading" />
  </div>
</template>

<script>
import Api from '../services/Api';
import FullScreenLoader from './FullScreenLoader';
import Store from '../store/Store';
import MaskedInput from 'vue-masked-input';
import { getMovieCategories } from '../utils/MovieCategoriesEnum';

export default {
  name: 'VideoUploader',
  components: { FullScreenLoader, MaskedInput },
  data() {
    return {
      movieCategories: getMovieCategories(),
      isUploading: false,
      video: {
        name: '',
        description: '',
        short_description: '',
        file: '',
        thumbnail: '',
        date: '',
        category: '',
        project: '',
        signature: '',
        playlist: 1,
        active: false,
        error: false,
      },
      playlists: [],
    };
  },
  mounted() {
    Store.getIsLogged().then(result => {
      if (!result) {
        this.$router.push('/');
      }
    });

    Api.getPlaylistsForSelect().then(playlist => {
      this.playlists = playlist;
    });
  },
  methods: {
    handleFileUpload() {
      this.video.file = this.$refs.file.files[0];
    },
    handleThumbnailUpload() {
      this.video.thumbnail = this.$refs.thumbnail.files[0];
    },
    submitFile() {
      if (!this.video.name || !this.video.file) {
        this.video.error = true;
        return false;
      }

      if (!this.video.name.trim().length || !(this.video.file instanceof File)) {
        this.video.error = true;
        return false;
      }

      this.video.error = false;

      let formData = new FormData();
      formData.append('file', this.video.file);
      formData.append('thumbnail', this.video.thumbnail);
      formData.append('name', this.video.name);
      formData.append('description', this.video.description);
      formData.append('short_description', this.video.short_description);
      formData.append('date', this.video.date);
      formData.append('category', this.video.category);
      formData.append('project', this.video.project);
      formData.append('active', this.video.active);
      formData.append('signature', this.video.signature);
      formData.append('playlist', this.video.playlist);

      this.isUploading = true;
      Api.uploadVideo(formData)
        .then(() => {
          this.$bvToast.toast(`Film został wgrany poprawnie`, {
            title: 'Sukces',
            autoHideDelay: 5000,
          });
          this.$router.push('/');
        })
        .catch(() => {
          this.isUploading = false;
          this.$bvToast.toast(`Wystąpił problem podczas wgrywania filmu`, {
            title: 'Uwaga',
            autoHideDelay: 5000,
          });
        });
    },
  },
};
</script>
<style scoped>
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
