<template>
  <perfect-scrollbar
    class="subtitle-container"
    :style="{ height: height ? height + 'px' : '100%' }"
  >
    <SubtitleItem
      v-for="(subtitle, index) in subtitles"
      :key="index"
      :data="subtitle"
      :currentTime="currentTime"
    />
  </perfect-scrollbar>
</template>

<script>
import SubtitleItem from "./SubtitleItem";
import Store from "../store/Store";

export default {
  name: "Subtitles",
  components: { SubtitleItem },
  props: ["subtitles", "currentTime"],
  data() {
    return {
      height: null,
      isLogged: false
    };
  },
  methods: {},
  mounted() {
    Store.getIsLogged().then(result => (this.isLogged = !!result));
  },
  created() {
    this.height = window.innerHeight - 100;
  }
};
</script>

<style scoped></style>
