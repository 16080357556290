<template>
  <div v-if="isLoading" class="loading">Loading&#8230;</div>
</template>

<script>
export default {
  name: "FullScreenLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
