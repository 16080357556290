export const movieCategories = {
  S: "Spektakl",
  C: "Rozmowa",
  L: "Wykład",
  P: "Materiał promocyjny",
  F: "Film"
};

export const getMovieCategories = () => {
  return Object.keys(movieCategories).map(key => {
    return { id: key, value: movieCategories[key] };
  });
};

export const getCategoryName = category => {
  return movieCategories[category] || "";
};
