<template>
  <div class="container login-component">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div v-if="loginForm.error" class="alert alert-warning" role="alert">
          Proszę uzupełnić brakujące pola!
        </div>
        <div v-if="loginError" class="alert alert-danger" role="alert">
          Niepoprawne dane do logowania!
        </div>

        <div class="card bg-light mb-3">
          <div class="card-header">Logowanie</div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="login">Login</label>
                <input
                  type="text"
                  class="form-control"
                  id="login"
                  v-model="loginForm.login"
                />
              </div>

              <div class="form-group">
                <label for="password">Hasło</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="loginForm.password"
                />
              </div>

              <button
                type="button"
                class="btn btn-secondary float-right"
                @click="loginBtnHandler"
              >
                Zaloguj
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
import Storage from "../utils/Storage";
import Store from "./../store/Store";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        login: null,
        password: null,
        error: false
      },
      loginError: false
    };
  },
  methods: {
    loginBtnHandler() {
      if (!this.loginForm.login || !this.loginForm.password) {
        this.loginForm.error = true;
        return false;
      }
      if (
        !this.loginForm.login.trim().length ||
        !this.loginForm.password.trim().length
      ) {
        this.loginForm.error = true;
        return false;
      }

      this.loginForm.error = false;

      Api.login(this.loginForm)
        .then(response => {
          Storage.set("token", response.token);
          Store.setIsLogged(true);
          window.location.href = "/";
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.loginError = true;
          this.loginForm.password = null;
        });
    }
  }
};
</script>
