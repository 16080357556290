<template>
  <span
    class="subtitle-item"
    :class="{ selected: currentTime >= data.start && currentTime <= data.end }"
    @click="clickHandler"
    @blur="blurHandler"
    v-if="data"
    >{{ data.text }}&nbsp;</span
  >
</template>

<script>
import Store from "../store/Store";
import { updateSubtitle } from "../store/Helpers";

export default {
  name: "SubtitleItem",
  props: ["data", "currentTime"],
  methods: {
    clickHandler($event) {
      if (Store.getIsEditMode()) {
        $event.target.setAttribute("contenteditable", true);
      }
      Store.setCurrentSubtitle(this.data);
      Store.event.$emit("subtitleClickEvent", this.data.start);
    },
    blurHandler($event) {
      $event.target.setAttribute("contenteditable", false);
      const data = { ...this.data, text: $event.target.innerText };
      updateSubtitle(data);
    }
  }
};
</script>

<style scoped></style>
