import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueRouter from 'vue-router';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import wysiwyg from 'vue-wysiwyg';

import App from './App.vue';
import MovieList from './components/MovieList';
import MovieDetails from './components/MovieDetails';
import Login from './components/Login';
import Logout from './components/Logout';
import VideoUploader from './components/VideoUploader';
import NotFound from './components/NotFound';
import VideoEdit from './components/VideoEdit';
import Playlists from './components/Playlists';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(PerfectScrollbar);
Vue.use(wysiwyg, {
  hideModules: { code: true, image: true, table: true, separator: true },
});

Vue.config.productionTip = false;

const routes = [
  { path: '/', component: MovieList },
  { path: '/movie/:slugId', component: MovieDetails },
  { path: '/edit/:editSlugId', component: VideoEdit },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/video/upload', component: VideoUploader },
  { path: '/playlists', component: Playlists },
  { path: '/not-found', component: NotFound },
  { path: '*', component: NotFound },
];
const router = new VueRouter({ routes });
// router.afterEach(to => {
//   if (to.params && to.params.slugId) {
//     document.body.style.overflow = 'hidden';
//   } else {
//     document.body.style.overflow = 'visible';
//   }
// });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
