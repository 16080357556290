import Vue from 'vue';
import { checkIsLogged } from './../store/Helpers';

class Store {
  constructor() {
    if (!Store.instance) {
      this.event = new Vue();
      this._data = {
        currentSubtitle: null,
        movies: [],
        videoElement: null,
        movie: null,
        isLogged: false,
        isWebshimSet: false,
        isEditMode: false,
      };

      Store.instance = this;
    }
    return Store.instance;
  }

  setCurrentSubtitle(subtitle) {
    this._data.currentSubtitle = subtitle;
  }

  getCurrentSubtitle() {
    return this._data.currentSubtitle;
  }

  setMovies(movies) {
    this._data.movies = movies;
  }

  getMovies() {
    return this._data.movies;
  }

  setVideoElement(video) {
    this._data.videoElement = video;
  }

  getVideoElement() {
    return this._data.videoElement;
  }

  setMovie(movie) {
    this._data.movie = movie;
  }

  getMovie() {
    return this._data.movie;
  }

  setIsWebshimSet(isSet) {
    this._data.isWebshimSet = isSet;
  }

  getIsWebshimSet() {
    return this._data.isWebshimSet;
  }

  setIsEditMode(isEditMode) {
    this._data.isEditMode = isEditMode;
  }

  getIsEditMode() {
    return this._data.isEditMode;
  }

  setIsLogged(isLogged) {
    this._data.isLogged = isLogged;
    this.event.$emit('changeLoggedEvent');
  }

  async getIsLogged() {
    if (this._data.isLogged) {
      return true;
    }
    const result = await checkIsLogged();
    if (result) {
      this.setIsLogged(true);
      return true;
    } else {
      return false;
    }
  }
}

const instance = new Store();
window.xStore = instance;
Object.freeze(instance);

export default instance;
